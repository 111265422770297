import React from "react";

function Unauthorized() {
  return (
    <>
      <h2>401 - Unauthorized</h2>
      <p>You do not have access to this resource</p>
    </>
  );
}

export default Unauthorized;
