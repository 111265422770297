const healthConditions = [
  "Abdominal aortic aneurysm",
  "Abortion",
  "Abscess",
  "Academic attainment",
  "Acanthosis nigricans",
  "Achalasia",
  "Acid and chemical burns",
  "Acid reflux in babies",
  "Acne",
  "Acoustic neuroma (vestibular schwannoma)",
  "Acromegaly",
  "Actinic keratoses (solar keratoses)",
  "Actinomycosis",
  "Acupuncture",
  "Acute cholecystitis",
  "Acute kidney injury",
  "Acute lymphoblastic leukaemia",
  "Acute myeloid leukaemia",
  "Acute pancreatitis",
  "Acute respiratory distress syndrome",
  "Addison's disease",
  "Adenoidectomy",
  "Age-related cataracts",
  "Age-related macular degeneration (AMD)",
  "Ageing",
  "Aggression",
  "Agoraphobia",
  "Air or gas embolism",
  "Albinism",
  "Alcohol misuse",
  "Alcohol poisoning",
  "Alcohol-related liver disease",
  "Alkaptonuria",
  "Allergic rhinitis",
  "Allergies",
  "Altitude sickness",
  "Alzheimer's disease",
  "Amblyopia",
  "Amputation",
  "Amyloidosis",
  "Anabolic steroid misuse",
  "Anaesthesia",
  "Anal cancer",
  "Anal fissure",
  "Anal fistula",
  "Anal pain",
  "Anaphylaxis",
  "Androgen insensitivity syndrome",
  "Angelman syndrome",
  "Angina",
  "Angioedema",
  "Animal and human bites",
  "Ankle pain",
  "Ankylosing spondylitis",
  "Anorexia nervosa",
  "Antenatal care",
  "Antiphospholipid syndrome (APS)",
  "Antisocial personality disorder",
  "Anxiety disorder in adults",
  "Anxiety disorders in children",
  "Aortic valve replacement",
  "Aphasia",
  "Appendicitis",
  "Arrhythmia",
  "Arterial thrombosis",
  "Arthritis",
  "Asbestosis",
  "Asperger's",
  "Aspergillosis",
  "Asthma",
  "Astigmatism",
  "Ataxia",
  "Atherosclerosis (arteriosclerosis)",
  "Athlete's foot",
  "Atopic eczema",
  "Atrial fibrillation",
  "Attention deficit hyperactivity disorder (ADHD)",
  "Auditory processing disorder (APD)",
  "Autism",
  "Autosomal dominant polycystic kidney disease",
  "Autosomal recessive polycystic kidney disease",
  "Back pain",
  "Bacterial vaginosis",
  "Bad breath",
  "Baker's cyst",
  "Balanitis",
  "Bartholin's cyst",
  "Basal cell carcinoma",
  "Bedwetting in children",
  "Behçet's disease",
  "Bell's palsy",
  "Benign brain tumour (non-cancerous)",
  "Benign prostate enlargement",
  "Bereavement",
  "Bile duct cancer (cholangiocarcinoma)",
  "Binge eating disorder",
  "Bipolar disorder",
  "Bird flu",
  "Birthmarks",
  "Bladder cancer",
  "Bladder stones",
  "Bleeding from the bottom (rectal bleeding)",
  "Blepharitis",
  "Blindness and vision loss",
  "Bloating",
  "Blood clots",
  "Blood donation",
  "Blood groups",
  "Blood in semen",
  "Blood in semen (haematospermia)",
  "Blood in urine",
  "Blood transfusion",
  "Blue skin or lips (cyanosis)",
  "Blushing",
  "Body dysmorphic disorder (BDD)",
  "Body image",
  "Body odour (BO)",
  "Boils",
  "Bone cancer",
  "Bone cyst",
  "Borderline personality disorder",
  "Botulism",
  "Bowel cancer",
  "Bowel incontinence",
  "Bowel polyps",
  "Bowel transplant",
  "Bowen's disease",
  "Brain abscess",
  "Brain aneurysm",
  "Brain death",
  "Brain haemorrhage",
  "Brain tumour (benign)",
  "Brain tumour (malignant)",
  "Brain tumours",
  "Breast abscess",
  "Breast cancer in men",
  "Breast cancer in women",
  "Breast lumps",
  "Breast pain",
  "Breastfeeding",
  "Bronchiectasis",
  "Bronchiolitis",
  "Bronchitis",
  "Bronchodilators",
  "Brucellosis",
  "Brugada syndrome",
  "Bulging eyes (exophthalmos)",
  "Bulimia",
  "Bullous pemphigoid",
  "Bunions",
  "Burns and scalds",
  "Bursitis",
  "Buttock pain",
  "Caesarean section",
  "Cancer",
  "Carbon monoxide poisoning",
  "Cardiomyopathy",
  "Cardiovascular disease",
  "Care homes",
  "Carers",
  "Carpal tunnel syndrome",
  "Cartilage damage",
  "Catarrh",
  "Cavernoma",
  "Cavernous sinus thrombosis",
  "Cellulitis",
  "Cerebral palsy",
  "Cervical cancer",
  "Cervical rib",
  "Cervical spondylosis",
  "Charcot-Marie-Tooth disease",
  "Charles Bonnet syndrome",
  "Chemotherapy",
  "Chest infection",
  "Chest pain",
  "Chiari malformation",
  "Chickenpox",
  "Chilblains",
  "Child development",
  "Childhood cataracts",
  "Chipped, broken or cracked tooth",
  "Chlamydia",
  "Cholangiocarcinoma",
  "Cholecystitis (acute)",
  "Cholera",
  "Cholesteatoma",
  "Chronic kidney disease",
  "Chronic lymphocytic leukaemia",
  "Chronic myeloid leukaemia",
  "Chronic obstructive pulmonary disease (COPD)",
  "Chronic pancreatitis",
  "Chronic traumatic encephalopathy",
  "Circumcision in boys",
  "Circumcision in men",
  "Cirrhosis",
  "Claustrophobia",
  "Cleft lip and palate",
  "Clinical depression",
  "Clostridium difficile (C. diff) infection",
  "Club foot",
  "Cluster headaches",
  "Coccydynia (tailbone pain)",
  "Coeliac disease",
  "Cognitive behavioural therapy (CBT)",
  "Cold sores",
  "Colic",
  "Colon cancer",
  "Colonoscopy",
  "Colostomy",
  "Colour vision deficiency (colour blindness)",
  "Common cold",
  "Compartment syndrome",
  "Complementary and alternative medicine",
  "Complementary therapies",
  "Complex regional pain syndrome",
  "Concussion",
  "Concussion",
  "Congenital heart disease",
  "Conjunctivitis",
  "Constipation",
  "Contact dermatitis",
  "Contraception",
  "Cornea transplant",
  "Corns and calluses",
  "Coronary angioplasty and stent insertion",
  "Coronary artery bypass graft",
  "Coronary heart disease",
  "Coronavirus (COVID-19)",
  "Corticobasal degeneration",
  "Cosmetic procedures",
  "Costochondritis",
  "Cough",
  "Coughing up blood (blood in phlegm)",
  "Counselling",
  "Cradle cap",
  "Craniosynostosis",
  "Creutzfeldt-Jakob disease",
  "Crohn's disease",
  "Croup",
  "Cushing's syndrome",
  "Cyanosis",
  "Cyclical vomiting syndrome",
  "Cyclospora",
  "Cyclothymia",
  "Cystic fibrosis",
  "Cystitis",
  "Cystoscopy",
  "Cytomegalovirus (CMV)",
  "Dandruff",
  "Deafblindness",
  "Deafness",
  "Dehydration",
  "Delirium",
  "Dementia (all)",
  "Dementia with Lewy bodies",
  "Dengue",
  "Dental abscess",
  "Dental pain",
  "Dentures (false teeth)",
  "Depression",
  "Detached retina (retinal detachment)",
  "Developmental co-ordination disorder (dyspraxia) in children",
  "Developmental dysplasia of the hip",
  "Diabetes",
  "Diabetes insipidus",
  "Diabetic eye screening",
  "Diabetic ketoacidosis",
  "Diabetic retinopathy",
  "Dialysis",
  "Diarrhoea",
  "Diarrhoea and vomiting",
  "Differences in sex development",
  "DiGeorge syndrome (22q11 deletion)",
  "Diphtheria",
  "Discoid eczema",
  "Dislocated kneecap",
  "Dislocated shoulder",
  "Disorders of consciousness",
  "Dissociative disorders",
  "Diverticular disease and diverticulitis",
  "Dizziness",
  "Domestic violence",
  "Double vision",
  "Down's syndrome",
  "Dry eyes",
  "Dry mouth",
  "Dupuytren's contracture",
  "DVT (deep vein thrombosis)",
  "Dysarthria (difficulty speaking)",
  "Dysentery",
  "Dyslexia",
  "Dysphagia (swallowing problems)",
  "Dyspraxia (developmental co-ordination disorder) in adults",
  "Dystonia",
  "Ear infections",
  "Earache",
  "Eardrum (burst)",
  "Early menopause",
  "Early or delayed puberty",
  "Earwax build-up",
  "Eating disorders",
  "Eating well",
  "Ebola virus disease",
  "Ectopic pregnancy",
  "Ectropion",
  "Edwards' syndrome (trisomy 18)",
  "Ehlers-Danlos syndromes",
  "Ejaculation problems",
  "Elbow and arm pain",
  "Electrocardiogram (ECG)",
  "Electroencephalogram (EEG)",
  "Embolism",
  "Empyema",
  "Encephalitis",
  "Endocarditis",
  "Endometrial cancer",
  "Endometriosis",
  "Endoscopy",
  "Epidermolysis bullosa",
  "Epididymitis",
  "Epiglottitis",
  "Epilepsy",
  "Erectile dysfunction (impotence)",
  "Erythema multiforme",
  "Erythema nodosum",
  "Erythromelalgia",
  "Ewing sarcoma",
  "Excessive daytime sleepiness (hypersomnia)",
  "Excessive hair growth (hirsutism)",
  "Excessive sweating (hyperhidrosis)",
  "Excessive thirst",
  "Exophthalmos (bulging eyes)",
  "Eye cancer",
  "Eye injuries",
  "Eyelid problems",
  "Fabricated or induced illness",
  "Fainting",
  "Falls",
  "Farting (flatulence)",
  "Febrile seizures",
  "Feeling sick (nausea)",
  "Female genital mutilation (FGM)",
  "Femoral hernia repair",
  "Fertility issues",
  "Fibroids",
  "Fibromyalgia",
  "Finger pain",
  "Fits (children with fever)",
  "Flat feet",
  "Floaters and flashes in the eyes",
  "Flu",
  "Foetal alcohol spectrum disorder",
  "Food allergy",
  "Food intolerance",
  "Food poisoning",
  "Foot drop",
  "Foot pain",
  "Foreskin problems",
  "Fractures, sprains and broken bones",
  "Frontotemporal dementia",
  "Frostbite",
  "Frozen shoulder",
  "Functional neurological disorder",
  "Fungal nail infection",
  "Gallbladder cancer",
  "Gallbladder removal",
  "Gallstones",
  "Ganglion cyst",
  "Gangrene",
  "Gastrectomy",
  "Gastritis",
  "Gastro-oesophageal reflux disease (GORD)",
  "Gastroenteritis",
  "Gastroparesis",
  "Gastroscopy",
  "Gender dysphoria",
  "General wellbeing",
  "Generalised anxiety disorder in adults",
  "Genetic screening",
  "Genital herpes",
  "Genital warts",
  "Gestational diabetes",
  "Giant cell arteritis",
  "Giardiasis",
  "Gigantism",
  "Gilbert's syndrome",
  "Glandular fever",
  "Glaucoma",
  "Glomerulonephritis",
  "Glue ear",
  "Glutaric aciduria type 1",
  "Goitre",
  "Gonorrhoea",
  "Gout",
  "Granuloma annulare",
  "Granulomatosis with polyangiitis",
  "Group B strep",
  "Growing pains",
  "Guillain-Barré syndrome",
  "Gum disease",
  "Haemochromatosis",
  "Haemophilia",
  "Haemophilus influenzae type b (Hib)",
  "Hair dye reactions",
  "Hair loss",
  "Hairy cell leukaemia",
  "Halitosis",
  "Hallucinations and hearing voices",
  "Hamstring injury",
  "Hand pain",
  "Hand tendon repair",
  "Hand, foot and mouth disease",
  "Hay fever",
  "Head and neck cancer",
  "Head injury and concussion",
  "Head lice and nits",
  "Headaches",
  "Health anxiety",
  "Healthy lifestyle",
  "Healthy volunteer",
  "Hearing aids and implants",
  "Hearing loss",
  "Hearing tests",
  "Heart attack",
  "Heart block",
  "Heart bypass",
  "Heart failure",
  "Heart pain",
  "Heart palpitations",
  "Heart palpitations and ectopic beats",
  "Heart rhythm problems",
  "Heart transplant",
  "Heart valve problems",
  "Heart valve replacement",
  "Heart-lung transplant",
  "Heartburn and acid reflux",
  "Heat exhaustion and heatstroke",
  "Heat rash (prickly heat)",
  "Heavy periods",
  "Heel pain",
  "Henoch-Schönlein purpura (HSP)",
  "Hepatitis",
  "Hepatitis A",
  "Hepatitis B",
  "Hepatitis C",
  "Hereditary haemorrhagic telangiectasia (HHT)",
  "Hereditary neuropathy with pressure palsies (HNPP)",
  "Hereditary spastic paraplegia",
  "Hernia",
  "Herpes simplex eye infections",
  "Herpetic whitlow (whitlow finger)",
  "Hiatus hernia",
  "Hiccups",
  "Hidradenitis suppurativa (HS)",
  "High blood pressure (hypertension)",
  "High blood sugar (hyperglycaemia)",
  "High cholesterol",
  "High temperature (fever) in adults",
  "High temperature (fever) in children",
  "Hip dysplasia",
  "Hip fracture",
  "Hip pain in adults",
  "Hip pain in children (irritable hip)",
  "Hip replacement",
  "Hirschsprung's disease",
  "HIV and AIDS",
  "Hives",
  "Hoarding disorder",
  "Hodgkin lymphoma",
  "Homocystinuria",
  "Hookworm",
  "Hormone headaches",
  "Hormone replacement therapy (HRT)",
  "Human papillomavirus (HPV)",
  "Huntington's disease",
  "Hydrocephalus",
  "Hydronephrosis",
  "Hyperglycaemia (high blood sugar)",
  "Hyperparathyroidism",
  "Hypertension",
  "Hypnotherapy",
  "Hypochondria",
  "Hypoparathyroidism",
  "Hypotension",
  "Hypothermia",
  "Hysterectomy",
  "Hysteroscopy",
  "Ichthyosis",
  "Idiopathic pulmonary fibrosis",
  "Ileostomy",
  "Impetigo",
  "Improving care and services",
  "Indigestion",
  "Infected piercings",
  "Infertility",
  "Inflammatory bowel disease",
  "Ingrown hairs",
  "Ingrown toenail",
  "Inguinal hernia repair",
  "Insect bites and stings",
  "Insomnia",
  "Interstitial cystitis",
  "Intracranial hypertension",
  "Intrauterine insemination (IUI)",
  "Iron deficiency anaemia",
  "Irregular periods",
  "Irritable bowel syndrome (IBS)",
  "Isovaleric acidaemia",
  "Itchy bottom",
  "Itchy skin",
  "IVF",
  "Japanese encephalitis",
  "Jaundice",
  "Jaw pain",
  "Jellyfish and other sea creature stings",
  "Jet lag",
  "Joint hypermobility syndrome",
  "Joint pain",
  "Kaposi's sarcoma",
  "Kawasaki disease",
  "Keloid scars",
  "Keratosis pilaris",
  "Kidney cancer",
  "Kidney failure",
  "Kidney infection",
  "Kidney stones",
  "Kidney transplant",
  "Klinefelter syndrome",
  "Knee ligament surgery",
  "Knee pain",
  "Knee replacement",
  "Knock knees",
  "Knocked-out tooth",
  "Kwashiorkor",
  "Kyphosis",
  "Labial fusion",
  "Labyrinthitis and vestibular neuritis",
  "Lactate dehydrogenase (LDH) test",
  "Lactose intolerance",
  "Lambert-Eaton myasthenic syndrome",
  "Laparoscopy (keyhole surgery)",
  "Laryngeal (larynx) cancer",
  "Laryngitis",
  "Laser eye surgery and lens surgery",
  "Lazy eye",
  "Learning disabilities",
  "Leg cramps",
  "Legionnaires' disease",
  "Leptospirosis (Weil's disease)",
  "Leukoplakia",
  "Lichen planus",
  "Lichen sclerosus",
  "Limping in children",
  "Lipoedema",
  "Lipoma",
  "Listeriosis",
  "Liver cancer",
  "Liver disease",
  "Liver transplant",
  "Local anaesthesia",
  "Long COVID",
  "Long QT syndrome",
  "Long-sightedness",
  "Loss of libido (reduced sex drive)",
  "Lost or changed sense of smell",
  "Low blood pressure (hypotension)",
  "Low blood sugar (hypoglycaemia)",
  "Low sex drive (loss of libido)",
  "Low sperm count",
  "Low white blood cell count",
  "Lumbar decompression surgery",
  "Lumbar puncture",
  "Lumps",
  "Lung cancer",
  "Lung transplant",
  "Lupus",
  "Lyme disease",
  "Lymphoedema",
  "Macular hole",
  "Magnesium test",
  "Malaria",
  "Malignant brain tumour (brain cancer)",
  "Mallet finger",
  "Malnutrition",
  "Maple syrup urine disease",
  "Marfan syndrome",
  "Mastitis",
  "Mastocytosis",
  "Mastoiditis",
  "MCADD",
  "Measles",
  "Medical cannabis (and cannabis oils)",
  "Memory loss (amnesia)",
  "Ménière's disease",
  "Meningitis",
  "Menopause",
  "Menstrual pain",
  "Mental health and wellbeing",
  "Mesothelioma",
  "Metabolic syndrome",
  "Metallic taste",
  "Middle East respiratory syndrome (MERS)",
  "Migraine",
  "Miscarriage",
  "Mitral valve problems",
  "Molar pregnancy",
  "Moles",
  "Molluscum contagiosum",
  "Monkeypox",
  "Morton's neuroma",
  "Motion sickness",
  "Motor neurone disease",
  "Mouth cancer",
  "Mouth ulcers",
  "MRSA",
  "Mucositis",
  "Multiple myeloma",
  "Multiple sclerosis",
  "Multiple system atrophy",
  "Mumps",
  "Munchausen syndrome",
  "Munchausen's syndrome",
  "Muscular dystrophy",
  "Myalgic encephalomyelitis or chronic fatigue syndrome (ME/CFS)",
  "Myasthenia gravis",
  "Mycobacterium chimaera infection",
  "Myelodysplastic syndrome (myelodysplasia)",
  "Myeloma",
  "Myositis (polymyositis and dermatomyositis)",
  "Nail patella syndrome",
  "Nail problems",
  "Narcolepsy",
  "Nasal and sinus cancer",
  "Nasal polyps",
  "Nasopharyngeal cancer",
  "Neck pain",
  "Necrotising fasciitis",
  "Neonatal herpes (herpes in a baby)",
  "Nephrotic syndrome in children",
  "Neuroblastoma",
  "Neuroendocrine tumours",
  "Neuroendocrine tumours and carcinoid syndrome",
  "Neurofibromatosis type 1",
  "Neurofibromatosis type 2",
  "Neuromyelitis optica",
  "Newborn jaundice",
  "Newborn respiratory distress syndrome",
  "Night sweats",
  "Night terrors and nightmares",
  "Nipple discharge",
  "Noise sensitivity (hyperacusis)",
  "Non-alcoholic fatty liver disease (NAFLD)",
  "Non-allergic rhinitis",
  "Non-gonococcal urethritis",
  "Non-Hodgkin lymphoma",
  "Noonan syndrome",
  "Norovirus (vomiting bug)",
  "Nose cancer",
  "Nosebleed",
  "Obesity",
  "Obesity risk",
  "Obsessive compulsive disorder (OCD)",
  "Occupational health",
  "Oesophageal atresia and tracheo-oesophageal fistula",
  "Oesophageal cancer",
  "Oral thrush (mouth thrush)",
  "Orf",
  "Osteoarthritis",
  "Osteomyelitis",
  "Osteophyte (bone spur)",
  "Osteoporosis",
  "Otosclerosis",
  "Ovarian cancer",
  "Ovarian cyst",
  "Overactive thyroid (hyperthyroidism)",
  "Ovulation pain",
  "Paget's disease of bone",
  "Paget's disease of the nipple",
  "Pain in the back of the hand",
  "Pain in the ball of the foot",
  "Pain in the bottom of the foot",
  "Pain in the palm of the hand",
  "Pain in the top of the foot",
  "Pain relief",
  "Pancreatic cancer",
  "Panic disorder",
  "Paralysis",
  "Parenting",
  "Parkinson's disease",
  "Patau's syndrome",
  "Pelvic inflammatory disease",
  "Pelvic organ prolapse",
  "Pelvic pain",
  "Pemphigus vulgaris",
  "Penile cancer",
  "Perforated eardrum",
  "Pericarditis",
  "Period pain",
  "Periods",
  "Peripheral arterial disease (PAD)",
  "Peripheral neuropathy",
  "Peritonitis",
  "Persistent trophoblastic disease and choriocarcinoma",
  "Personality disorder",
  "Personality disorders",
  "Phaeochromocytoma",
  "Phenylketonuria",
  "Phimosis",
  "Phlebitis (superficial thrombophlebitis)",
  "Phobias",
  "Piles (haemorrhoids)",
  "Pilonidal sinus",
  "Pins and needles",
  "Pityriasis rosea",
  "Pityriasis versicolor",
  "Plagiocephaly and brachycephaly (flat head syndrome)",
  "Plantar fasciitis",
  "Pleurisy",
  "PMS (premenstrual syndrome)",
  "Pneumonia",
  "Poisoning",
  "Polio",
  "Polycystic ovary syndrome",
  "Polycythaemia",
  "Polyhydramnios (too much amniotic fluid)",
  "Polymorphic light eruption",
  "Polymyalgia rheumatica",
  "Pompholyx (dyshidrotic eczema)",
  "Popliteal cyst",
  "Post-herpetic neuralgia",
  "Post-polio syndrome",
  "Post-traumatic stress disorder (PTSD)",
  "Postmenopausal bleeding",
  "Postnatal depression",
  "Postpartum psychosis",
  "Postural tachycardia syndrome (PoTS)",
  "Prader-Willi syndrome",
  "Pre-eclampsia",
  "Pregnancy",
  "Pressure ulcers (pressure sores)",
  "Priapism (painful erections)",
  "Primary biliary cholangitis (primary biliary cirrhosis)",
  "Problems swallowing pills",
  "Proctalgia",
  "Progressive supranuclear palsy",
  "Prolonged grief disorder",
  "Prosopagnosia (face blindness)",
  "Prostate cancer",
  "Prostate enlargement",
  "Prostate problems",
  "Prostatitis",
  "Psoriasis",
  "Psoriatic arthritis",
  "Psychiatry",
  "Psychosis",
  "Psychotic depression",
  "Pubic lice",
  "Public health",
  "Pudendal neuralgia",
  "Pulmonary embolism",
  "Pulmonary hypertension",
  "Pyoderma gangrenosum",
  "Q fever",
  "Quinsy",
  "Rabies",
  "Rashes in babies and children",
  "Raynaud's",
  "Reactive arthritis",
  "Rectal bleeding",
  "Rectal cancer",
  "Red blood cell count",
  "Red eye",
  "Reflux in babies",
  "Repetitive strain injury (RSI)",
  "Respiratory syncytial virus (RSV)",
  "Respiratory tract infections (RTIs)",
  "Restless legs syndrome",
  "Restricted growth (dwarfism)",
  "Retinal migraine",
  "Retinoblastoma (eye cancer in children)",
  "Rett syndrome",
  "Reye's syndrome",
  "Rhesus disease",
  "Rheumatic fever",
  "Rheumatoid arthritis",
  "Rickets and osteomalacia",
  "Ringworm",
  "Rosacea",
  "Roseola",
  "Roundworm",
  "Rubella (german measles)",
  "Salivary gland stones",
  "Sarcoidosis",
  "SARS (severe acute respiratory syndrome)",
  "Scabies",
  "Scarlet fever",
  "Scars",
  "Schistosomiasis (bilharzia)",
  "Schizophrenia",
  "Sciatica",
  "Scleroderma",
  "Scoliosis",
  "Scurvy",
  "Seasonal affective disorder (SAD)",
  "Seizures (children with fever)",
  "Seizures (fits)",
  "Selective mutism",
  "Self-harm",
  "Sepsis",
  "Septic arthritis",
  "Severe head injury",
  "Sexually transmitted infections (STIs)",
  "Shin splints",
  "Shingles",
  "Short-sightedness (myopia)",
  "Shortness of breath",
  "Shoulder impingement",
  "Shoulder pain",
  "Sick building syndrome",
  "Sickle cell disease",
  "Silicosis",
  "Sinus cancer",
  "Sinusitis (sinus infection)",
  "Sjögren's syndrome",
  "Skin cancer (melanoma)",
  "Skin cancer (non-melanoma)",
  "Skin cyst",
  "Skin picking disorder",
  "Skin tags",
  "Slapped cheek syndrome",
  "Sleep apnoea",
  "Sleep paralysis",
  "Sleeping well",
  "Sleepwalking",
  "Slipped disc",
  "Small bowel transplant",
  "Smelly feet",
  "Smelly urine",
  "Smoking",
  "Snake bites",
  "Snoring",
  "Social anxiety (social phobia)",
  "Soft tissue sarcomas",
  "Soiling (child pooing their pants)",
  "Sore or dry lips",
  "Sore or white tongue",
  "Sore throat",
  "Spina bifida",
  "Spinal muscular atrophy",
  "Spleen problems and spleen removal",
  "Spondylolisthesis",
  "Sports injuries",
  "Sprains and strains",
  "Squamous cell carcinoma",
  "Squint",
  "Stammering",
  "Staph infection",
  "Statins",
  "Stem cell and bone marrow transplants",
  "Steroids",
  "Stevens-Johnson syndrome",
  "Stillbirth",
  "Stomach ache",
  "Stomach bug",
  "Stomach cancer",
  "Stomach ulcer",
  "Stopped or missed periods",
  "Stretch marks",
  "Stroke",
  "Stuttering",
  "Stye",
  "Subarachnoid haemorrhage",
  "Subdural haematoma",
  "Sudden confusion (delirium)",
  "Sudden infant death syndrome (SIDS)",
  "Suicidal thoughts",
  "Sunburn",
  "Supplements",
  "Supraventricular tachycardia (SVT)",
  "Sweating (excessive)",
  "Swine flu (H1N1)",
  "Swollen ankles, feet and legs (oedema)",
  "Swollen arms and hands (oedema)",
  "Swollen glands",
  "Syphilis",
  "Tailbone (coccyx) pain",
  "Tapeworm",
  "Tay-Sachs disease",
  "Teeth grinding (bruxism)",
  "Teeth whitening",
  "Temporal arteritis",
  "Temporomandibular disorder (TMD)",
  "Tendonitis",
  "Tennis elbow",
  "Tension headaches",
  "Tension-type headaches",
  "Testicle lumps and swellings",
  "Testicle pain",
  "Testicular cancer",
  "Tests, scans and screening",
  "Tetanus",
  "Thalassaemia",
  "The 'male menopause'",
  "Thoracic outlet syndrome",
  "Threadworms",
  "Thrombophilia",
  "Thrush in men and women",
  "Thumb pain",
  "Thyroid cancer",
  "Thyroiditis",
  "Tick-borne encephalitis (TBE)",
  "Tics",
  "Tight foreskin (phimosis)",
  "Tinnitus",
  "Toe pain",
  "Tongue cancer",
  "Tongue-tie",
  "Tonsillitis",
  "Tooth decay",
  "Toothache",
  "Topical corticosteroids",
  "Tourette's syndrome",
  "Toxic shock syndrome",
  "Toxocariasis",
  "Toxoplasmosis",
  "Tracheostomy",
  "Transient ischaemic attack (TIA)",
  "Tremor or shaking hands",
  "Trichomoniasis",
  "Trichotillomania (hair pulling disorder)",
  "Trigeminal neuralgia",
  "Trigger finger",
  "Trimethylaminuria ('fish odour syndrome')",
  "Tuberculosis (TB)",
  "Tuberous sclerosis",
  "Tummy bug",
  "Turner syndrome",
  "Twitching eyes and muscles",
  "Type 1 diabetes",
  "Type 2 diabetes",
  "Typhoid fever",
  "Typhus",
  "Ulcerative colitis",
  "Underactive thyroid (hypothyroidism)",
  "Undescended testicles",
  "Unintentional weight loss",
  "Urinary incontinence",
  "Urinary tract infections (UTIs)",
  "Urine albumin to creatinine ratio (ACR)",
  "Uveitis",
  "Vaccinations",
  "Vaginal cancer",
  "Vaginal discharge",
  "Vaginal dryness",
  "Vaginal pain",
  "Vaginismus",
  "Vaginitis",
  "Varicose eczema",
  "Varicose veins",
  "Vascular dementia",
  "Vasculitis",
  "Vegetative state",
  "Venous leg ulcer",
  "Vertigo",
  "Vitamin B12 or folate deficiency anaemia",
  "Vitiligo",
  "Vomiting",
  "Vomiting blood",
  "Vomiting blood (haematemesis)",
  "Von Willebrand disease",
  "Vulval cancer",
  "Vulvodynia (vulval pain)",
  "Warts and verrucas",
  "Watering eyes",
  "West Nile virus",
  "Whiplash",
  "Whooping cough",
  "Wind",
  "Wolff-Parkinson-White syndrome",
  "Womb (uterus) cancer",
  "Worms in humans",
  "Wrist pain",
  "Yellow fever",
  "Zika virus",
];

export default healthConditions;
